import React from 'react';

const NotAllowBorder = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="70"
    height="70"
    viewBox="0 0 70 70"
  >
    <defs>
      <clipPath>
        <rect width="39.859" height="39.851" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-610 -1780)">
      <g transform="translate(-201 1509)">
        <g transform="translate(811 271)" fill="none" stroke="currentColor" strokeWidth="1">
          <rect width="70" height="70" stroke="none" />
          <rect x="0.5" y="0.5" width="69" height="69" fill="none" />
        </g>
      </g>
      <g transform="translate(625.07 1794.574)">
        <g clipPath="url(#clip-path)">
          <path
            d="M19.921,39.851A19.919,19.919,0,0,1,5.837,5.844,19.762,19.762,0,0,1,19.905,0h.048a19.926,19.926,0,0,1-.015,39.851Zm-11.726-6.6.317.272A17.782,17.782,0,0,0,37.743,19.879,17.365,17.365,0,0,0,33.524,8.508L33.251,8.2ZM19.893,2.13A17.752,17.752,0,0,0,7.679,7.013,17.692,17.692,0,0,0,6.325,31.349l.272.313L31.651,6.613l-.309-.272A17.487,17.487,0,0,0,19.893,2.13"
            transform="translate(0)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default NotAllowBorder;
